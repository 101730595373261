const PtDesType = {
    Stop: 1,
    PassengersPlatform: 2,
    Siding: 3,
    FreightYard: 4,
    Other: 5,
    Connection: 6
}
Object.freeze(PtDesType)
export default PtDesType
